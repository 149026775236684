import React, {useEffect, useState} from 'react';
import {Container} from '../Components/Container';
//import bgImage from '../assets/bg/background_1920-15.jpg';
import {FaMapMarker,FaClock, MdDateRange, MdLocationOn, FiPhoneCall} from 'react-icons/all'
//import AboutImage from '../assets/about.jpg';
import GoogleMapReact from 'google-map-react';
import phone from '../assets/app-phone.png';
import app_store from '../assets/app-store.svg';
import google_play from '../assets/google-play.png';
import {TicketPurchase} from '../Components/TicketCard';
import {SpeakerCard} from '../Components/SpeakerCard';
import {connect} from 'react-redux'

import PropTypes from 'prop-types';
import {Table} from 'reactstrap';
const QRCode = require('qrcode.react');

 //import {Row, Col} from 'reactstrap';
//import appleStore from '../assets/logo/appleplay.png';
//import GooglePlay from '../assets/logo/googleplay.png';
const GOOGLE_MAPS_APIKEY="AIzaSyANWoD-ka4R1BtDJzSeiglNP5o9H2W3P4Y";
// const ticket =[
//     {
//         amount:50000,
//         name:"Ticket for 4",
        
//     }
// ]


const RootPage = (props)=>{
    const [about, setAbout] = useState("");
    const [state, setState] = useState({about_event:{},event_speakers:[],tickets:[]});
    const [ticket, setTicket] = useState([]);
   // const [start, setStart] = useState(false);
let total = 0;
    const [defaultPos, setDefaultPos] = useState( {center: {
        lat: 6.5764,
        lng: 3.3653
      },
      zoom: 15});


    useEffect(()=>{
       // if(!start) return;
       const {error,event, eventDetails}  = props.event;
       if(!error){
           if(eventDetails.data){
               if(!eventDetails.data.about_event) return;
            setAbout(eventDetails.data.about_event.description);
            let emptyTicket = [];
            eventDetails.data.tickets.map((item,i)=>{
                emptyTicket.push({
                    "name":item.name,
                    "AQ":item.quantity,//quantity available
                    "quantityS":0,//quantity Selected
                    "price":item.price,
                    "id":item.id
                })
            });
            setTicket(emptyTicket);
            setState(eventDetails.data);
            console.log(eventDetails.data);
            
            setDefaultPos(
                {center: {
                    lat: parseFloat(eventDetails.data.about_event.latitude),
                    lng: parseFloat(eventDetails.data.about_event.longitude)
                  },
                  zoom: 15}
            )
           }
            
       }else{
           const {history} = props;
          // console.log(props.event)
           history.push("/error")
       }
    },[props.event])


   const selectTicketQuantity=(i,q)=>{
      // alert(i+q);
      //  setTicket({...state,[name]:quantity});
      let tempStore;
      tempStore={
          ...ticket[i],
          ["quantityS"]:q,

      }
        ticket[i]=tempStore;
      setTicket([...ticket]);
      console.log(ticket);
    }

    return(
        <Container>
           <section className="image-container" style={{backgroundImage:`linear-gradient(to bottom, rgba(3, 15, 41, 0.899), rgba(3, 15, 41, 0.763), rgba(3, 15, 41, 0.153)), url(${'https://solabstore.nyc3.digitaloceanspaces.com/engage/events/'+state.about_event.avatar_url})`}}>
                    <p style={{fontSize:"24px",color:state.about_event.text_color,textTransform:"uppercase",wordSpacing:"5px", lineHeight:"40px"}}>{about}</p>
                    <div className="get-app">
                       <button  style={{backgroundColor:state.about_event.event_color,color:state.about_event.text_color, fontSize:"14px"}} onClick={(()=>{window.location.href="#registerEvent"})} className="create-event-button">Register for Event</button>
                       <button style={{color:state.about_event.event_color,backgroundColor:state.about_event.text_color, fontSize:"14px"}} onClick={(()=>{window.location.href="#buyTicket"})} className="create-event-button">Buy Ticket</button>
                    </div>
           </section>
           <section id="organizers" style={{height:"60vh"}}   className="about-section">
            <h2 className="organizer-h4"><u>About Organizers</u></h2>
            <div className="row">
                <div className="col-6 about-text">
                    <h3>{state.about_event.o_name} </h3>
                    <article style={{textAlign:"center"}} className="organizer-article">
                       {state.about_event.o_about}
                    </article>
                    <aside className="event-button">
                        <button>Join Event</button>
                        <button>Buy Ticket</button>
                        <button>Download App</button>
                    </aside>
                </div>
                <div className="col-6">
                    <img width="100%" height="300px" src={"https://solabstore.nyc3.digitaloceanspaces.com/engage/events_organizers/"+state.about_event.o_pic} alt="organizers logo"/>
                </div>
            </div>
           </section>
           <section id="registerEvent" style={{height:"100vh"}}  className="about-section attend-event">
            <h2 style={{color:"yellow"}} className="organizer-h4"><u>Attend Event</u></h2>
            <div className="row">
                <div className="col-6 center-phone">
                    <div style = {{backgroundColor:"#fff", padding:"14px"}}>
                            {state.about_event?<QRCode style={{width:"250px", height:"250px"}} value={String(state.about_event.url)}/>:<div></div>}
                    </div>                    
                </div>
                <div className="col-6">
                    <h3><u style={{color:"white"}}>How to Attend :</u></h3>
                    <ul className="attend-procedure">
                        {/* <li>You can <a style={{color:"yellow"}} href="#buyTicket">Purchase ticket here</a> or </li> */}
                        <li>Download the app on GooglePlay or app store</li>
                        <li>Register or create an account</li>
                        <li>Scan the QR code on this page</li>
                        <li>Click on Join Button</li>
                        <li>Purchase a Ticket</li>
                    </ul>
                    <aside>
                        <span style={{marginRight:"25px"}}>
                            <img alt="app store logo" src={app_store}/>
                        </span>
                        <span>
                            <img height="40px" width="150px" alt="google play logo" src={google_play}/>
                        </span>
                    </aside>
                </div>
            </div>
            </section>
           <section id="contact" style={{ margin:"0px", padding:"5px"}} className="venu-section">
                <h2 className="organizer-h4"><u>Venue</u></h2>
                <div className="row">
                    <div  className="col-6 center-content">
                    <h1> <FaMapMarker style={{color:"red", marginRight:"10px"}}/>{state.about_event.venue} </h1>
                    <ul>
                    <li><MdDateRange style={{color:"green", marginRight:"10px"}}/>{state.about_event.date_start?state.about_event.date_start.split(' ')[0]:state.about_event.date_start} to {state.about_event.date_end?state.about_event.date_end.split(' ')[0]:state.about_event.date_end} </li>
                        <li><FaClock style={{color:"black", marginRight:"10px"}}/>{state.about_event.time_start} - {state.about_event.time_end} </li>
                        <li><FiPhoneCall style={{color:"blue", marginRight:"10px"}}/>{state.about_event.o_phone}</li>
                    </ul>
                    </div>
                    <div className="col-6">
                        <GoogleMapReact
                        bootstrapURLKeys={{ key: GOOGLE_MAPS_APIKEY }}
                        defaultCenter={defaultPos.center}
                        defaultZoom={defaultPos.zoom}
                        >
                        <MdLocationOn
                        lat={defaultPos.lat}
                        lng={defaultPos.lng}
                        text="Event Venue"
                        className="mapMaker"
                        style={{color:"red"}}
                        size={50}
                        />
                        </GoogleMapReact>
                    </div>
                </div>
           </section>
            {/* <section  style={{height:"110vh", backgroundColor:"#f4f4f4"}} id="buyTicket" className="buy-ticket">
                <h2 className="organizer-h4"><u>Buy Tickets :</u></h2><br/>
                  <div className="ticket-div" style={{overflow:"scroll", marginBottom:"40px"}}>
                      <table >
                          <tbody>
                              <tr>
                              {state.tickets.map((value,i)=>{
                                  return(
                                       <td  key={i}><TicketPurchase i={i} selectTicketQuantity={(i,quantity)=>selectTicketQuantity(i,quantity)} event={state.about_event} data={value}/></td>
                                  )
                              })}
                               </tr>
                          </tbody>
                      </table>
                  </div>
                  <div style={{border:"1px solid gray", padding:"20px", borderRadius:"10px", backgroundColor:"white"}}>
                 
                   <table style={{border:"1px solid black"}}>
                       <thead>
                           <th style={{border:"1px solid gray"}}>Ticket Name</th>
                           <th style={{border:"1px solid gray"}}>Quantity Selected</th>
                           <th style={{border:"1px solid gray"}}>Quantity Available</th>
                           <th style={{border:"1px solid gray"}}>price</th>
                           <th style={{border:"1px solid gray"}}>total</th>
                       </thead>
                       <tbody>
                           {
                               ticket.map((item,index)=>{
                                   let subtotal = 0;
                                  // total=0;
                                   if(parseInt(item.quantityS)>0){
                                       subtotal = 0;
                                       subtotal = parseFloat(item.price)*parseFloat(item.quantityS);
                                       total += subtotal;
                                       console.log(total);
                                       return(
                                           <tr>
                                               <td style={{border:"1px solid gray"}}>{item.name}</td>
                                               <td style={{border:"1px solid gray"}}>{item.quantityS}</td>
                                               <td style={{border:"1px solid gray"}}>{item.AQ}</td>
                                               <td style={{border:"1px solid gray"}}>{item.price}</td>
                                               <td style={{border:"1px solid gray"}}>{subtotal}</td>
                                           </tr>
                                       )
                                       }
                               })
                           }
                           <tr>
                               <td colSpan={2}>Total</td>
                               <td colSpan={2}>{total} </td>
                           </tr>
                       </tbody>
                   </table>
                   <br/><br/>
                  <button style={{width:"250px",fontSize:"16px", backgroundColor:"gray", height:"40px", color:"white", borderRadius:"10px"}}>Checkout</button>
                  </div>
            </section> */}
            <section id="speakers" style={{height:"50vh"}}>
                <h2 className="organizer-h4"><u>Event Speakers</u></h2><br/>
                <div style={{overflow:"scroll"}}>
                <table className="speaker-list">
                    <tbody>
                        <tr>
                            {state.event_speakers.map((item,index)=>{
                                return(
                                    <td key={index}><SpeakerCard data={item} /></td>
                                )
                            })}
                            
                        </tr>
                    </tbody>
                    
                </table>
                </div>
            </section>
        </Container>
    )
}
RootPage.propTypes = {
    event:PropTypes.func.isRequired
}
const mapStateToProps = (state)=> ({
    event:state.event
});

export default connect(mapStateToProps,{})(RootPage)