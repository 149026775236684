import React from 'react';
import {Route,BrowserRouter,Switch} from 'react-router-dom';
import Layout from './Components/Layout';
import ErrorPage from './Pages/ErrorPage';
import BuyTicket from './Pages/BuyTicket';
import './Styles/index.css';
//import {Container} from './Components/Container';
import RootPage from './Pages/index';

const App = (props)=>{
    return(
      <BrowserRouter>
        <Switch>
            <Layout>
               <Route exact path="/" component={RootPage}/>
               <Route exact path="/buyTicket" component={BuyTicket}/>
               <Route exact path="/error" component={ErrorPage}/>
            </Layout>
        </Switch>
       
        
       
      </BrowserRouter>
    )
  
}

export default App;
