import {GET_EVET_DETAILS, GET_EVET_DETAILS_FAILED} from '../types';

const initialState ={
    error:false,
    errors:{},
    data:false,
    eventDetails:{}
}

const getEventDetailsReducer = (state=initialState, action) =>{
    switch(action.type){
        case GET_EVET_DETAILS:
            return{
                ...state,
                error:false,
                data:true,
                eventDetails:action.payload
            }
        case GET_EVET_DETAILS_FAILED :
            return{
                ...state,
                data:false,
                error:true,
                errors:action.payload
                 
            }
        default :
        return{
            ...state
        }
    }
}

export default getEventDetailsReducer;