import React from 'react';

export const SpeakerCard = ({data})=>{
    return(
        <div className = "speaker-card" >
            <div title="he is very good i love it" className="img-flex">
                <img width="150px" height="150px" style={{borderRadius:"100%"}} alt="speaker img" src={`https://solabstore.nyc3.digitaloceanspaces.com/engage/speakers/${data.avatar_url}`}/>
                <h4 style={{lineHeight:"1px"}}>{data.name}</h4>
                <small>{data.role}</small>
            </div>
        </div>
    )
}