import axios from 'axios';
import {GET_EVET_DETAILS, GET_EVET_DETAILS_FAILED} from '../types'
//VSDDCSfla
export const getEventDetails = (data) =>dispatch=>{
    axios.post(`https://sandbox.app-engage.com/v1/subdomain/${data}`)
    .then(res=>{
        if(!res.hasError){
            dispatch({
                type:GET_EVET_DETAILS,
                payload:res.data
            })
        }else{
            dispatch({
                type:GET_EVET_DETAILS_FAILED,
                payload:res.data
            })
        }
    }).catch(err=>{
        dispatch({
            type:GET_EVET_DETAILS_FAILED,
            payload: {hasError:true,errors:{message:"network Error"}}
        })
    })
}