import React from 'react';
import {Container} from '../Components/Container';

const BuyTicket = (props)  =>{
    return(
         <Container>
             <section style={{marginTop:"100px"}}>
                <h1>Hello</h1>
             </section>
         </Container>
    )
}

export default BuyTicket;