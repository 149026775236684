import React, {useState,useEffect} from 'react';
import {Container} from './Container';
import {getEventDetails} from '../redux/action/getEventDetails';
// import HorseIcon from '../assets/horse.png';
// import Logo16 from '../assets/logo/favicon-16x16.png'
// import Logo32 from '../assets/logo/favicon-32x32.png'
// import Logo96 from '../assets/logo/favicon-96x96.png';
import {connect} from 'react-redux';

const Layout = props=>{
    const [state, setState] = useState({eventName:"",eventLogo:null, event_color:"yellow"});

    useEffect(()=>{
        const url = window.location.host;
       // setStart(true);
        let arrurl = url.split('.');
         if(arrurl[0]==="www"){
           props.getEventDetails(arrurl[1]);
         }else{
           props.getEventDetails(arrurl[0]);
         }
        console.log(arrurl);
      
    },[])

    
    useEffect(()=>{
        const {data,eventDetails} = props.event
        if(data===true){
            if(eventDetails.data){
                //if(!props.event.eventDetail) {window.location.href="/error"; return; }
                setState({...state,event_color:props.event.eventDetails.data.about_event.event_color, eventName:props.event.eventDetails.data.about_event.title,eventLogo:"https://solabstore.nyc3.digitaloceanspaces.com/engage/events/"+props.event.eventDetails.data.about_event.event_splash});
            }
        }
       
    },[props.event])
    return(
    <Container>
       <header className="page-header">
            <nav className="page-logo">
                <picture>
                    <img width="50px" height="50px" style={{borderRadius:"100%"}} alt="Event Logo" src={state.eventLogo}/>
                </picture>
                <h3 style={{color:state.event_color}}>{state.eventName}</h3>
            </nav>
            <nav className="page-links">
                <ul>
                    <li><a className="links-event" href="#">Home</a></li>
                    <li><a className="links-event" href="/#organizers">Organizers</a></li>
                    <li><a className="links-event" href="/#contact">Contact</a></li>
                    <li><a className="links-event" href="/#contact">Venue</a></li>
                    <li><a className="links-event" href="/#buyTicket">Ticket</a></li>
                    <li><a className="links-event" href="/#speakers">Speakers</a></li>
                   
                    
                    <button onClick={()=>{window.location.href="https://app-engage.com"}} className="create-event-button">Create Event</button>
                </ul>
            </nav>
        </header>
        <main className="main-container">
            {props.children}
        </main>
        <footer style={{height:"20vh", backgroundColor:"black", marginTop:"20px"}}>
           the footer
        </footer>
    </Container>

    )
}
const mapStateToProps =state=>({
    event:state.event
})
export default connect(mapStateToProps,{getEventDetails})(Layout);